import React from "react";
import Layout from "../components/layout";
import Favicon from "../images/favicon.svg";
import Seo from "../components/seo";
import { Link } from "gatsby";
const Policies = () => {
  return (
    <Layout>
      <Seo
        title="Policies"
        url="https://kaladhwani.com/policies"
        image={Favicon}
      />
      <div
        id="terms"
        className="staticPage bg-white py-12 flex justify-center flex-col items-start w-full lg:px-28 md:px-12 px-4 m-auto"
      >
        <h1>Kaladhwani policies</h1>
        <p>
          This page contains the following policies. We encourage you to go
          through these carefully if you are visiting the website and
          considering to leave your details with us.
        </p>
        <div className="flex flex-col gap-2">
          <ul className="">
            <li>
              <Link to="#terms">Terms of Use</Link>
            </li>
            <li>
              <Link to="#privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="#refund">Refund/Cancellation Policy</Link>
            </li>
          </ul>
        </div>

        <h2>Kaladhwani Terms of Use:</h2>
        <p>Effective from 1st June, 2020</p>
        <h3>INTRODUCTION</h3>
        <p>
          Kaladhwani, (the “NGO”) is a registered Trust (NGO) incorporated under
          the India Trust Act 1882 with Trust Number 358 and having its
          registered office at Delhi, India. The NGO owns and operates an online
          Website i.e.{" "}
          <a href="https://kaladhwani.com/" target="_blank" rel="noreferrer">
            https://www.kaladhwani.com
          </a>
          , that allows Students (refer 2.2) to learn Bharatanatyam by taking
          classes at Kaladhwani.
        </p>
        <p>
          By accessing or using the website or by downloading any content from
          or on the Website, you would be indicating that you have read, and
          that you understand and agree to be bound by these terms and receive
          our Services (“Terms of Services” or “Terms”), whether or not you have
          registered with the Website.
        </p>
        <p>
          Therefore, please read these Terms of Services before accessing or
          using the Website or downloading any content from or on the Website,
          carefully as they contain important information regarding your legal
          rights, remedies and obligations.
        </p>
        <p>
          If you do not agree to these Terms, then you have no right to access
          or use the Website, Website, Applications or its Services.
        </p>
        <p>
          If you are using the Website, Website, Application or Services then
          these Terms of Service are binding between you and the NGO.
        </p>
        <h3>DEFINITIONS</h3>
        <p>
          In addition to other words and expressions that may be defined
          elsewhere in this document, unless the context otherwise requires, the
          following capitalized terms wherever used in the Agreement shall have
          the meanings as ascribed hereunder:
        </p>
        <ul className="decimal">
          <li>
            <strong>“Student”</strong> means a Member who has registered on the
            Website as a Student. In case of a minor student, this term refers
            to the parent or guardian who registers their child or ward as a
            Student.
          </li>
          <li>
            <strong>“Website”</strong> refers to{" "}
            <a href="https://kaladhwani.com/" target="_blank" rel="noreferrer">
              https://www.Kaladhwani.com
            </a>{" "}
            which is the primary website that the NGO owns and operates to
            provide and offer its Services.
          </li>
        </ul>
        <h3>TERMS OF SERVICE</h3>
        <p>
          This ‘Terms of Service’ is formulated and published pursuant to the
          provisions of Rule 3 (1) of the Information Technology (Intermediaries
          Guidelines) Rules, 2011 that requires the publication of Terms of
          Service and Privacy Policy for access or usage of a Website, app or
          related services. The NGO reserves the right to modify the terms
          contained in this Agreement at any time by posting such modifications
          on the Website without requiring any advance notice to you..
        </p>
        <p>
          These Terms &amp; Conditions set out the agreement in this regard
          between the NGO and the Students who are registered on the Website, as
          well as between the NGO and the Providers and Partners who are
          registered on the Website.
        </p>
        <ul className="decimal">
          <li>
            <h4>TERMS APPLICABLE TO ALL USERS</h4>
            <p>
              Subject to the User’s compliance with the terms hereof, the NGO
              hereby grants to each User a limited, non-exclusive,
              non-transferable, worldwide license, without the right to
              sublicense, solely for the purpose of enabling the User to use and
              enjoy the benefit of Services, in the manner permitted by these
              terms. The User shall not copy, modify, distribute, sell, or lease
              any part of the Services or included software, nor may a User
              reverse engineer or attempt to extract the source code of that
              software, unless local laws prohibit those restrictions, or you
              have the prior written permission from the NGO. No User shall:
            </p>
            <ol className="list-[lower-alpha] alpha">
              <li>use the Services except to the extent permitted above;</li>
              <li>
                modify or create any derivative work of any part of the
                Services;
              </li>
              <li>permit any third parties to use the Services; or</li>
              <li>
                market, sublicense, publish, distribute, reproduce, assign,
                transfer, rent, lease or loan the Service
              </li>
            </ol>
            <p>
              This Agreement is available to any person over the age of eighteen
              (18) years who visits the website as a Student. Persons below the
              age of eighteen (18) shall not be entitled to use the Website
              unless such use is through the User account of a parent or a legal
              guardian. In such a case, “Student” as referred to herein refers
              to the concerned parent or legal guardian (as the case maybe).
            </p>
            <p>
              Each User of the Website agrees and acknowledges the following:
            </p>
            <ul className="decimal">
              <li>
                The NGO should not be thought of as the authority and the final
                guide in your decision making. All decisions shall be taken at
                your own risk and volition, and subject to independent
                verification of the data provided on the Website.
              </li>
              <li>
                The NGO at its sole discretion may edit, delete or block access
                to any Content. The NGO will however make reasonable efforts to
                inform you of the changes
              </li>
            </ul>
          </li>
          <li>
            <h4>PAYMENT TERMS</h4>
            <p>
              You agree to pay to the NGO any fees determined by the NGO at the
              time of signing up for the Services. You also agree that You will
              be solely responsible for payment of any and all statutory dues
              including but not limited to sales, use, import, export, value
              added or property tax, GST, duties or other amounts that arise in
              connection with Your use of the NGO’s Services.
            </p>
          </li>
          <li>
            <h4>
              PERSONAL DATA AND OTHER INFORMATION MADE AVAILABLE ON THE WEBSITE
            </h4>
            <p>
              The User shall read and understand the Privacy Policy available on
              this page, so as to ensure that he or she has the knowledge of,
              (i) certain information that may be collected by the Website; (ii)
              the purpose of collection and the use that such information is put
              to; and (iii) the entity that collects and retains the information
              etc.
            </p>
            <ul>
              <li>
                The NGO is not responsible for the authenticity of the personal
                information or personal data that is supplied by a User on the
                Website. The User shall be obligated to intimate the NGO
                regarding any misuse or suspected misuse of his/her user Account
                information.
              </li>
              <li>
                Where a User provides information that is not authentic or is
                incomplete, the NGO may at its sole discretion discontinue
                providing the Services to that User by disabling his/her User
                Account.
              </li>
            </ul>
            <p>
              Each User represents and warrants to the NGO that the content
              shared by them to the NGO shall not infringe the intellectual
              property rights of any person.
            </p>
          </li>
        </ul>
        <h3>CONTENT AND INTELLECTUAL PROPERTY TERMS</h3>
        <p>
          The proprietary rights to all information that is created by the NGO
          and made available on the Website shall belong to the NGO alone.
          Unauthorized use of any content or material that is available on the
          Website may violate intellectual property rights of other parties. You
          shall use the content on this Website only for personal,
          non-commercial use. Any links to third-party websites that are
          provided on the Website are for convenience and do not amount to the
          NGO being responsible, in any manner, for such third party websites
          and their content. You may use such third party content at your own
          risk.
        </p>

        <p>
          The Website shall not be used by you for any illegal or unlawful
          purpose. Your use of the Website shall be in compliance with all
          applicable laws and regulations. You shall not interfere or hinder the
          use of the Website by other Users or Users, and you shall not engage
          in transmission of "spam", chain letters, junk mail or any other type
          of unsolicited communication.
        </p>

        <p>
          By using this Website, you are granted a limited, non-exclusive,
          non-transferable right to use the content and materials on the Website
          in a lawful manner. You may not copy, reproduce, transmit, distribute,
          or create derivative works of such content or information without
          express written authorization from the NGO or the applicable third
          party (if content belonging to such third party is being used).
        </p>
        <p>
          The NGO retains the right to remove from the Website any content that
          contravenes any of the above conditions.
        </p>
        <h3>CODE OF CONDUCT</h3>
        <p>
          If you are found guilty of misbehaviour in a class or sharing content
          that meets one of the following criteria, it will lead to an immediate
          removal from the course you have registered and/or permanent removal
          from the Website:
        </p>
        <ul>
          <li>Violate any law, statute, ordinance or regulation;</li>
          <li>defamatory, unlawfully threatening or unlawfully harassing;</li>
          <li>obscene or contain pornography;</li>
          <li>
            use of content that contain any viruses, trojan horses, worms,
            cancelbots or other computer programming routines that may damage,
            detrimentally interfere with, surreptitiously intercept or
            expropriate any system, data or personal information.
          </li>
        </ul>
        <h3>DISCLAIMER OF WARRANTIES</h3>
        <p>
          Please note that your use of the Website and the services shall be at
          your sole risk. The NGO disclaims all warranties of any kind, whether
          express or implied, including, but not limited to non-infringement of
          third party rights with respect to the contents of the Website, or any
          reliance upon or use of the Website contents or the Services.
        </p>
        <p>
          THE SITE, CONTENT, AND SERVICES ARE PROVIDED AS IS, WITHOUT WARRANTY
          OR CONDITION OF ANY KIND, EITHER EXPRESSED OR IMPLIED. IN NO EVENT
          SHALL THE NGO, BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL, PUNITIVE, CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, BUT
          NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR
          OTHER INTANGIBLE LOSSES RESULTING FROM THE USE OR THE INABILITY TO USE
          OUR SERVICES.
        </p>
        <p>
          THE NGO MAKES NO WARRANTY REGARDING THE QUALITY OF ANY SERVICES,
          ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR INFORMATION
          PURCHASED OR OBTAINED THROUGH THE SITES, CONTENT OR SERVICES.YOU
          SPECIFICALLY ACKNOWLEDGE THAT THE NGO SHALL NOT BE LIABLE FOR THE
          DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT
          THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
        </p>
        <p>
          <strong>Interruptions:</strong> The NGO (including its trustees,
          employees, agents and representatives) shall not be liable for any
          loss or liability resulting, directly or indirectly, from technical
          glitches or interruptions in the access of the Website due to
          electronic or mechanical failures, defects, weather, strikes, acts of
          God, riots or other like causes.
        </p>
        <p>
          <strong>Inaccuracies:</strong> The contents published on the Website
          from time to time may include inaccuracies or human as well as
          mechanical errors, may be incomplete, and may be changed or updated
          from time to time at the sole discretion of the NGO. The NGO
          (including its trustees, employees, agents and representatives) make
          no representations about the suitability or use of the content and
          features of the Website for any particular purpose. IN NO EVENT SHALL
          THE NGO BE LIABLE TO A USER OR ANY THIRD PARTY FOR ANY SPECIAL,
          PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND,
          OR ANY OTHER DIRECT OR INDIRECT DAMAGES, INCLUDING WITHOUT LIMITATION,
          THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT THE
          NGO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY
          THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF
          THE WEBSITE OR OF ANY OTHER WEBSITES REFERENCED OR LINKED TO FROM THE
          WEBSITE OR Website. THE NGO SHALL NOT BE LIABLE IN ANY WAY FOR THIRD
          PARTY GOODS AND/OR SERVICES OFFERED THROUGH THE Website OR FOR
          ASSISTANCE IN CONDUCTING COMMERCIAL TRANSACTIONS THROUGH THE Website,
          INCLUDING WITHOUT LIMITATION THE PROCESSING OF ORDERS OR PAYMENT OF
          ANY CONSIDERATION.
        </p>
        <p>
          Further, the NGO makes no warranty that the content made available on
          the Website is complete, updated or accurate; or
        </p>
        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          Except for the breach of confidentiality obligations, under no
          circumstances and under no legal theory, whether tort (including
          negligence), product liability, contract, or otherwise, shall the NGO
          be liable to you for any indirect, special, incidental, or
          consequential damages, even if you have been informed of the
          possibility of such damages
        </p>
        <h3>INDEMNIFICATION</h3>
        <p>
          You shall indemnify, defend and hold harmless the NGO as well as each
          of its representatives, employees, contractors, agents, officers,
          trustees from all liabilities, claims, damages, costs and expenses,
          including reasonable attorneys' fees and expenses in relation to all
          claims arising out of (a) a breach of the obligations undertaken by
          you under the terms of this Agreement; (b) use or misuse by you of the
          Website content; (c) breach of any intellectual property or other
          proprietary rights of any person or entity; or (d) the inaccuracy or
          incompleteness of any information or data you supplied on the Website.
          If and when so required, the NGO may seek written assurances from you
          regarding your undertaking to indemnify the NGO, and your failure to
          provide such assurances would amount to a material breach of this
          Agreement. The NGO may participate in any legal proceedings where you
          are defending a third party claim related to your use of any of the
          Website. You shall notify the NGO of any third party legal proceeding
          that is initiated in relation to the Website or the Services, to which
          legal proceeding you are a party.
        </p>
        <h3>REDRESSAL OF GRIEVANCES</h3>
        <p>
          If you have any questions or grievances regarding the Website, or the
          contents thereof, you may reach out to the NGO Secretary ‘Radhika
          Kathal’ at{" "}
          <a href="mailto:radhika@kaladhwani.com">radhika@kaladhwani.com</a>{" "}
          (the <strong>“Company Secretary”</strong>). The Company Secretary
          shall address any complaint or grievance that is raised by a User
          within a period of one (1) month from when it is raised.
        </p>
        <h3>USE OUTSIDE INDIA</h3>
        <p>
          Although the Website may be accessible worldwide, the NGO makes no
          representation that materials on the Website are appropriate or
          available for use in locations outside India or that the Website
          complies with the laws that are applicable in any jurisdiction that is
          outside India. Accessing the Website from territories where the
          content of the Website and/or the provision of the Services is deemed
          illegal is prohibited. Users accessing the Website from locations
          outside India do so at their own risk and are responsible for
          compliance with local laws that are applicable to them. Any offer for
          any Service, and/or information made in connection with the Website is
          void where prohibited.
        </p>
        <h3>MISCELLANEOUS PROVISIONS</h3>
        <p>
          <u>Force Majeure</u>. The NGO shall not be responsible for any
          cessation, interruption or delay in the performance of its obligations
          hereunder due to earthquake, flood, fire, storm, pandemic, State
          imposed lockdown restrictions, natural disaster, act of God, war,
          terrorism, armed conflict, labor strike, lockout, or boycott. The NGO
          may at any time, in its sole discretion and without advance notice to
          you, cease operation of the Website.
        </p>
        <p>
          <u>Entire Agreement</u>. This Agreement along with the Privacy Policy
          comprises the entire agreement between you and the NGO with respect to
          the use of the Website.
        </p>
        <p>
          <u>No Waiver</u>. A delay or failure by the NGO to exercise or enforce
          any right or provision of this Agreement will not constitute a waiver
          of such right or provision.
        </p>
        <p>
          <u>Notices</u>: All notices given to you by the NGO or by you to the
          NGO shall be in writing and in the English language. Such notice shall
          be sent by e-mail or mailed by a prepaid internationally-recognized
          courier service to the intended recipient at the address set out
          below, or any changed address that is notified by either Party:
        </p>
        <p className="text-black">Notice to the NGO:</p>
        <p>
          Kaladhwani
          <br />
          Plot No. 21, third floor, pocket-2,
          <br />
          Jasola vihar,behind apollo hospital,
          <br />
          New delhi - 110025
          <br />
          Email: radhika@kaladhwani.com
        </p>
        <p className="text-black">Notice to User:</p>
        <p>
          At the email address provided by you at the time of registration on
          the Website.
        </p>
        <p>
          <u>Governing Law Jurisdiction</u>. This Agreement is governed by the
          provisions of Indian law, including but not limited to the following:
        </p>
        <ul className="decimal">
          <li>the Indian Contract Act, 1872;</li>
          <li>the (Indian) Information Technology Act, 2000;</li>
          <li>
            the (Indian) Information Technology (Reasonable Security Practices
            and Procedures and Sensitive Personal Information) Rules, 2011; and
          </li>
        </ul>
        <p>
          This Website originates from the State of Delhi in India. This
          Agreement will be governed by the laws that are applicable in the
          State of Delhi. By using this Website, you consent to the jurisdiction
          and venue of the courts located in Delhi, India in connection with any
          action, suit, proceeding or claim arising under or by reason of this
          Agreement.
        </p>
        <p>
          <u>Termination.</u> The NGO may terminate your access to the Website
          without any notice to you if it reasonably believes, in its sole
          discretion, that you have breached any of the terms and conditions of
          this Agreement.
        </p>
        {/* <div className="my-8 bg-black/30 w-full h-[1px]" /> */}
      </div>
      <div
        id="privacy"
        className="staticPage bg-black/[0.02] pb-8 pt-4 w-full flex flex-col lg:px-28 md:px-12 px-4"
      >
        <h2>Privacy Policy</h2>
        <p>Effective from 1st June, 2020</p>
        <p>
          This document is published by virtue of a legal requirement under the
          provisions of the Information Technology Act, 2000 that requires
          websites to publish the rules and regulations, privacy policy and
          terms for access or usage of the website and is primarily governed by
          Information Technology (Reasonable Security Practices and Procedures
          and Sensitive Personal Data or Information) Rules, 2011.
        </p>
        <p>
          Your Consent
          <br />
          By accessing{" "}
          <a href="https://kaladhwani.com/" target="_blank" rel="noreferrer">
            https://kaladhwani.com/
          </a>{" "}
          (“our Website”) and using it on a continued basis, you expressly
          consent and confirm to Kaladhwani collecting, maintaining, using,
          processing and disclosing your personal and other information in
          accordance with this Privacy Policy.
        </p>
        <p>
          Commitment <br />
          Kaladhwani intends to protect the privacy of its users (whether or not
          registered with Kaladhwani) on its website being and the privacy of
          their data provided to Kaladhwani from time to time. We encourage you
          to read our privacy statement to understand what types of personally
          identifiable information we collect and how we use this information.
          For the purpose of contacting you, our Website collects preliminary
          personal information that allows us to identify you as a user and know
          more about your requirements. We encourage you to very carefully
          review the privacy policy of any third-party website you visit before
          using it and to be very cautious before you disclose your personal
          information on such websites.
        </p>
        <h3>What type of information does Kaladhwani.com collect from you?</h3>
        <p>
          <strong>Personal Information</strong>
        </p>
        <p>
          If you are a prospective customer looking to get connected with
          Kaladhwani professionals, our Website collects your name, email ID,
          personal phone number and details of personal interest. This
          information is used by to get in touch with and understand your
          requirements in detail. Such information is kept strictly
          confidential. Kaladhwani also collects your other personal details
          including but not limited to your feedback, suggestions, views,
          comments, etc. that may be shared/volunteered by you on our other
          pages which you may access or visit on our Website. Such information
          being in the public domain is available for access for all our
          Website’s users and visitors. In this regard, you are advised to
          exercise discretion before you make such details public, as such
          information may be vulnerable to mis-use and can be easily misused.
          Kaladhwani in this regard fully disclaims any liability(ies) or
          claim(s) which may arise by use/misuse of such information shared by
          you, by any third party or any party not known to Kaladhwani.
        </p>
        <p>
          All other information exchanged by you with Kaladhwani in the form of
          written communication, responses to emails, feedback required from
          you, participation in discussions, etc. shall be deemed to be
          confidential and shall be owned by Kaladhwani and shall be available
          for the exclusive use of Kaladhwani. Our Website does not collect
          information about you unless you specifically and knowingly provide
          it. Kaladhwani DOES NOT trade or sell your information in any manner,
          except as specified herein, or if express consent is sought from you.
        </p>
        <p>
          <strong>Non-Personal Information</strong>
        </p>
        <p>
          Kaladhwani also collects certain other information from you on your
          visit to our Website such as your information about your operating
          system, browser type, the URL of the previous web site you visited,
          your Internet service provider and your IP Address (“Non-personal
          Information”). This information cannot be easily used to personally
          identify you. We use Non-personal information for the purposes
          including but not limited to troubleshoot connection problems,
          administer the Website, analyze trends, gather demographic
          information, to ascertain how our visitors use our website, including
          information relating to the frequency of visits to our web site,
          average length of visits, pages viewed during a visit, ensuring
          compliance with the applicable law, co-operating with law enforcement
          activities, etc. This information is used to improve the site content
          and performance. You also agree that Kaladhwani may gather usage
          statistics and usage data from your use of our Website to evaluate
          your use of our products/services, to improve our products/services,
          Website content and to ensure that you are complying with the terms of
          the applicable agreements between you and Kaladhwani. The statistics
          and data collected may or may not be aggregated. These statistics
          contain no information that can distinctly identify you.
        </p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          Similar to other commercial web sites, our Website utilizes standard
          technology called "Cookies" and Web server logs to collect information
          about how our Website is used. Cookies are small pieces of information
          that are stored by your browser on your computer's hard drive. Cookies
          are only read by the server that places them, and are unable to inter
          alia run programs on your computer, plant viruses or harvest your
          personal information. Cookies allow Web servers to recognize your
          computer each time you return to our Website including the date and
          time of visits, the pages viewed, time spent at our Website, and the
          Websites visited just before and just after our Website, verify your
          registration or password information (in case of registered users)
          during a particular session so that you do not have to input your
          password multiple times as you navigate the site and suggest products
          or services that may interest you. This information is collected on an
          aggregate basis. None of this information is associated with you as an
          individual.
        </p>
        <p>
          Most web browsers automatically accept cookies. By changing the
          options on your web browser or using certain software programs, you
          can control how and whether cookies will be accepted by your browser.
          Kaladhwani understands and supports your right to block any unwanted
          Internet activity, especially that of unscrupulous websites. However,
          blocking Kaladhwani cookies may disable certain features on our
          Website and may make it impossible for you to use certain services
          available on our Website.
        </p>
        <p>
          <strong>Session Data</strong>
        </p>
        <p>
          We automatically log generic information about your computer's
          connection to the Internet, which we call "session data", that is
          anonymous and not linked to any personal information. Session data
          consists of information such as the IP address, operating system, type
          of browser software being used by you and the activities conducted by
          you on our Website. An IP address is a number that lets computers
          attached to the Internet, such as our web servers, know where to send
          data back to the user, such as the pages of the site the user wishes
          to view. We collect session data because it helps us analyze things
          such as the items visitors are likely to click on most, the manner in
          which visitors click preferences on our Website, number of visitors
          surfing to various pages on the site, time spent by the visitors on
          our Website and frequency of their visit. It also helps us diagnose
          problems with our servers and lets us administer our systems better.
          Although such information does not identify any visitor personally, it
          is possible to determine from an IP address a visitor's Internet
          Service Provider (ISP), and the approximate geographic location of his
          or her point of connectivity.
        </p>
        <p>
          <strong>Other</strong>
        </p>
        <p>
          We may from time to time add or enhance products/services available on
          our Website. To the extent these products/services are provided to and
          used by you, we will use the information you provide in this regard to
          facilitate the products/service requested. For example, if you email
          us with a question, we will use your email address, name, nature of
          the question, etc. to respond to your question. We may also store and
          publish such information to assist us in making the site better and
          easier to use.
        </p>
        <p>
          <strong>Promotional Initiatives</strong>
        </p>
        <p>
          Kaladhwani’s presence on social networking websites including but not
          limited to LinkedIn, Facebook, Twitter, Youtube, Google Plus, etc. is
          only a promotional initiative to invite registrations and
          participation of prospective customers located in different parts of
          the country. The domain links contained therein may either direct you
          to our Website or request your participation by way of feedback,
          suggestions. Kaladhwani in this regard fully disclaims any
          liability(ies) or claim(s) which may arise by use/misuse of your
          feedback, suggestions, views, etc. on any of the aforementioned
          networking websites or blogs, by any third party whether or not known
          to Kaladhwani.
        </p>
        <h3>How does Kaladhwani protect my Personal Information?</h3>
        <p>
          Kaladhwani is committed to protecting the privacy and the
          confidentiality of your personal information. Whenever Kaladhwani
          obtains personal information from you, our Website uses commercially
          reasonable efforts and general industry standards to protect it from
          any unauthorized access or disclosure. Save as otherwise provided
          herein, access to your personal information is limited to Kaladhwani’s
          personnel and such authorized third parties who may host your data on
          our behalf or may assist us in providing the products and services
          requested by you. Kaladhwani uses its best endeavours to maintain
          physical, electronic and procedural safeguards that aim to protect
          your information against loss, misuse, damage, modification, and
          unauthorized access or disclosure. However, Kaladhwani assumes no
          liability whatsoever for any disclosure of personal information due to
          unauthorized third party access or other acts of third parties, or any
          other acts or omissions beyond reasonable control of Kaladhwani.
        </p>
        <h3>How does Kaladhwani use the information that it collects?</h3>
        <p>
          Your information may be used by us to support your interaction with us
          and to provide you our Services and products that we offer. By
          submitting your personal information to Kaladhwani, you expressly
          acknowledge and consent to Kaladhwani to use such information and to
          process the same in a manner deemed fit by Kaladhwani. This may
          involve conducting data analysis, research based on reviews about
          deals,, etc.
        </p>
        <h3>
          What security precautions are in place to protect against the loss,
          misuse or alteration of your information?
        </h3>
        <p>
          Kaladhwani makes every reasonable effort to preserve the privacy and
          confidentiality of your information shared with us. We implement
          standard measures and mechanisms complaint with the applicable laws to
          protect against unauthorized access to and unlawful interception of
          your personal information. However, no Internet site can fully
          eliminate security risks. We reserve the right to disclose the
          information collected from you without your consent (express or
          implied) and without any liabilities to you when required or permitted
          by law and we have a bonafide belief that
        </p>
        <ul className="none">
          <li>
            (i) such action is necessary to comply with an appropriate law
            enforcement investigation, current judicial proceeding, a court
            order or legal process served on us or
          </li>
          <li>
            (ii) to conform to the legal requirements, compliance/reporting to
            administrative and judicial authorities, as may be required
          </li>
          <li>
            (iii) protect and defend the rights or property of the website
            owners of Kaladhwani, or the users of Kaladhwani,
          </li>
        </ul>
        <p>
          <strong>
            Closing your Account and removing your Personal Information
          </strong>
        </p>
        <p>
          If you no longer wish to participate in our Services, you may close
          your account by contacting us at{" "}
          <a href="mailto:radhika@kaladhwani.com">radhika@kaladhwani.com</a>. If
          you close your Kaladhwani account and you choose to delete all your
          data, we will remove your name and other contact and identifiable
          information from our database. However, we may retain information
          related to you if we believe it may be necessary to prevent fraud or
          future abuse, or for legitimate business purposes, such as analysis of
          aggregated, de-identified information, account recovery, auditing our
          records, enforcing our rights and obligations under our agreements, or
          if required by law. Kaladhwani may also retain and use your
          information if necessary to provide the Services to other Registered
          Users. For example, just as an email you may send to another person
          through an email service provider resides in that person’s inbox even
          after you delete it from your sent files or close your account,
          messages you send through Kaladhwani to other Users may remain visible
          to others after you have closed your account. Similarly, other
          information you have shared with others, or that others have copied,
          may also remain visible. Kaladhwani disclaims any liability in
          relation to the deletion or retention (subject to the terms herein) of
          information, or any obligation not to delete the information.
          Kaladhwani does not control when search engines update their search
          index or cache, which may contain certain profiles or other
          information that have since been removed from Kaladhwani’s Site.
        </p>
        <h3>How does Kaladhwani address children's privacy?</h3>
        <p>
          Kaladhwani requires that the visitor must be at least 18 years old to
          have our permission to use this site. If you are below 18 years of
          age, you are strictly prohibited from registering on our Website or
          doing any act which leads us to believe that you are 18 years of age
          or above. If you are the parent or guardian of a child under 18 years
          of age and believe that they have disclosed personal information to
          us, please contact us at{" "}
          <a href="mailto:radhika@kaladhwani.com">radhika@kaladhwani.com</a> so
          that we may take immediate steps to delete the child's information.
        </p>
        <h3>I have more questions about privacy policy</h3>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at{" "}
          <a href="mailto:radhika@kaladhwani.com">radhika@kaladhwani.com</a>.
        </p>
        <h3>Will Kaladhwani change privacy policy in the future?</h3>
        <p>
          This policy provides a general statement of the ways in which
          Kaladhwani aims to protect your personal information. You may,
          however, in connection with specific products or services offered by
          Kaladhwani, be provided with privacy policies or statements that
          substitute or supplement this policy. The terms and conditions of this
          policy may be changed from time to time, without your consent (express
          or implied) to reflect changes in our practices concerning the
          collection and use of your personal information. The revised policy
          will be effective immediately upon posting to our web site unless
          otherwise mentioned. This version of the policy is effective from 1st
          June, 2020. You shall be bound by the revised Privacy Policy and it is
          your sole responsibility to check the same from time to time. You
          acknowledge that no knowledge of the revised Privacy Policy shall be a
          defence in any proceedings with regard to the Privacy Policy.
        </p>
        <h3>I have a complaint/concern</h3>
        <p>
          In accordance with Information Technology Act 2000 and rules made
          there under, the name and contact details of the Company Secretary who
          can be contacted with respect to any complaints or concerns including
          those pertaining to breach of Kaladhwani’s Terms of Use, Privacy
          Policy and other polices or questions are published as under: <br />
          Company Secretary Name: Radhika Kathal <br />
          Email address:{" "}
          <a href="mailto:radhika@kaladhwani.com">
            radhika@kaladhwani.com
          </a>{" "}
          <br />
          She can be contacted between 9:00 AM to 5:00 PM from Monday to Friday
          except on public holidays.
        </p>
      </div>
      {/* <div className="my-8 bg-black/30 w-full h-[1px]" /> */}
      <div
        id="refund"
        className="staticPage pb-8 pt-4 w-full flex flex-col lg:px-28 md:px-12 px-4"
      >
        <h2>Refund/Cancellation Policy</h2>
        <p>
          Kaladhwani follows the following on all student fees and donations
          made towards the Kaladhwani NGO.
        </p>
        <ul>
          <li>Donations once made cannot be refunded.</li>
          <li>
            For refunds requested on Student fees paid within 7 days from 1st
            class taken - Will be 100% refunded.
          </li>
          <li>
            For refunds requested on Student fees paid after 7 days from 1st
            class taken - No refunds can be made. However if there are special
            circumstances please write to us on{" "}
            <a href="mailto:radhika@kaladhwani.com">radhika@kaladhwani.com</a>.
            The decision to refund the fees lies solely with Kaladhwani.
          </li>
        </ul>
      </div>
    </Layout>
  );
};
export default Policies;
